import React, { Component } from "react"
import { Link } from "gatsby"
import * as styles from "./news.module.scss"

import { getNewsPairings } from "../../../utils/news-utils"
import { NEWS_DATA } from "../../../data/news"
import { NewsContainer } from "./newsContainer"

class News extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDesktopOrTablet: true,
      displayViewAllNewsText: true,
    }
  }

  componentDidMount() {
    this.updateViewport()
    window.addEventListener("resize", this.updateViewport)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateViewport)
  }

  updateViewport = () => {
    this.setState({ isDesktopOrTablet: window.innerWidth > 812 })
  }

  setNewsView = currentNewsView => {
    this.setState({
      displayViewAllNewsText: !currentNewsView,
    })
  }

  render() {
    return (
      <div className={styles.newsSection}>
        <div className={styles.newsHeader}>
          <h2>Featured News</h2>
          {this.state.isDesktopOrTablet ? (
            <Link to="/news">
              <button className={styles.viewAllButton}>View All</button>
            </Link>
          ) : null}
        </div>

        {getNewsPairings(NEWS_DATA)
          .map((newsPair, i) => (
            <NewsContainer
              first={newsPair.first}
              second={newsPair.second}
              key={`news-container-${i}`}
            />
          ))
          .slice(0, 2)}
        {!this.state.isDesktopOrTablet && this.state.displayViewAllNewsText ? (
          <Link to="/news">
            <button className={styles.viewAllButton}>View All</button>
          </Link>
        ) : null}
      </div>
    )
  }
}

export default News
