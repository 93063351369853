import React, { Component } from "react"
import styled from "styled-components"
import * as styles from "./whyIronCap.module.scss"
import ModalVideo from "react-modal-video"
import ProgressiveImage from "react-progressive-image-loading"
import "react-modal-video/scss/modal-video.scss"
import videobg from "../../../../assets/why-video-bg.png"
import videobgmin from "../../../../assets/why-video-bg-min.png"
import { TM } from "../../common/typography/trademark"
import { VideoPlayButton } from "../../common/videoPlayButton/video-play-button"
import { theme } from "../../../tokens"

const VideoButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35%;
  // TODO: is there a better solution than cascade? Revisiting mediaQuery function
  @media (max-width: ${theme.breakpoints.lg}) {
    width: 40%;
  }

  @media (max-width: ${theme.breakpoints.md}) {
    width: 30%;
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    width: 50%;
    margin-top: -20px;
  }
`

class WhyIronCap extends Component {
  constructor(props) {
    super()
    this.state = {
      isOpen: false,
      isBrowser: false,
    }
    this.openModal = this.openModal.bind(this)
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      this.setState({
        isBrowser: true,
      })
    }
    if (!document.getElementById("wistia_script")) {
      var wistiaScript = document.createElement("script")
      wistiaScript.id = "wistia_script"
      wistiaScript.type = "text/javascript"
      wistiaScript.src = "https://fast.wistia.com/assets/external/E-v1.js"
      wistiaScript.async = true
      wistiaScript.popover = true
      document.body.appendChild(wistiaScript)
    }
  }

  openModal() {
    this.setState({ isOpen: true })
    console.log(
      "👉👉👉 ",
      this.state.isOpen,
      this.state.isBrowser,
      typeof window,
      typeof window !== "undefined"
    )
  }
  render() {
    return (
      <ProgressiveImage
        preview={videobgmin}
        src={videobg}
        transitionTime={500}
        transitionFunction="ease"
        render={(src, style) => (
          <div
            className={styles.whyIronCap}
            style={Object.assign(style, { backgroundImage: `url(${src})` })}
          >
            <div className={styles.whyVideo}>
              {this.state.isBrowser && (
                <ModalVideo
                  channel="youtube"
                  isOpen={this.state.isOpen}
                  videoId="Hdq9guLK93A"
                  onClose={() => this.setState({ isOpen: false })}
                />
              )}
            </div>
            <div className={styles.bannerContainer}>
              <div className={styles.bannerContainerText}>
                <h3>
                  Why IronCAP
                  <TM />
                </h3>
                <p>
                  IronCAP
                  <TM /> is based-on NIST-approved post-quantum cryptography
                  (PQC) technology. It is designed to operate on conventional
                  computer systems as we know them today, and is also secure
                  enough to safeguard against future attacks from the world of
                  quantum computers. Examples of vertical applications are
                  email/file encryption, digital signatures, blockchain
                  security, remote access/VPN, password management, credit card
                  security, cloud storage, artificial intelligence, IoT, and
                  website security.
                </p>
              </div>
              <button
                className={styles.playWhyButton}
                onClick={this.openModal}
              />
            </div>
          </div>
        )}
      />
    )
  }
}
export default WhyIronCap
