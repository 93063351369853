import React, { Component } from "react"

import Layout from "../components/layout"
import { Helmet } from "react-helmet"

import VerticalApplications from "../components/sections/verticalApplications/verticalApplications"
import News from "../components/sections/news/news"
import CTABanner from "../components/common/ctaBanner/ctaBanner"
import MainCarousel from "../components/sections/carousel/mainCarousel"
import WhyIronCap from "../components/sections/whyIronCap/whyIronCap"
import HowItWorks from "../components/sections/howItWorks/howItWorks"
class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ie: false,
    }
  }
  componentDidMount() {
    if (typeof window !== `undefined`) {
      let isIE = /*@cc_on!@*/ false || !!document.documentMode
      this.setState({
        ie: isIE,
      })
    }
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Post-Quantum Cybersecurity | Post-Quantum Cryptography</title>
          <meta
            name="keywords"
            content="quantum-safe file encryption, post quantum cyber, post quantum cyber security, post-
            quantum cyber security, Post-quantum cybersecurity"
          />
          <meta
            name="description"
            content="World’s most proven quantum-safe data encryption,
            backed by cryptographers and scientists. Easily integrates
            with existing systems, bulletproof encryption and works for
            conventional computers too."
          />
          <link rel="canonical" href="https://ironcap.ca" />
        </Helmet>
        <MainCarousel />
        {/* <div style={{ height: "100vh" }} /> */}
        <WhyIronCap />
        <VerticalApplications />
        <HowItWorks />
        <News IE={this.state.ie} />
        <CTABanner
          tagline="Step Into The Future"
          CTA="video"
          CTADetail="how video"
        />
      </Layout>
    )
  }
}

export default IndexPage
