import React from "react"
import ProgressiveImage from "react-progressive-image-loading"
import LearnMoreButton from "../../common/learnMoreButton/learnMoreButton"
import * as styles from "./carousel.module.scss"

export const Slide = ({
  img,
  imgStatic,
  bg,
  bgMin,
  ie,
  isDesktopOrTablet,
  slideNumber,
  header,
  secondHeader,
  description,
  styleProp,
  alt,
  ctaType,
  ctaDetail,
}) => {
  return (
    <ProgressiveImage
      preview={bgMin}
      src={bg}
      transitionTime={500}
      transitionFunction="ease"
      render={(src, style) => (
        <div
          className={styles[`carouselGraphic${slideNumber}`]}
          style={Object.assign(style, {
            backgroundImage: `url(${src})`,
          })}
        >
          <div className={styles.carouselText}>
            <h4>{header}</h4>
            <h3>{secondHeader}</h3>
            <p>{description}</p>
            {slideNumber === 1 ? (
              <LearnMoreButton
                CTA={ctaType}
                CTADetail={ctaDetail}
                customText="Rethinking Cybersecurity"
              />
            ) : (
              <LearnMoreButton CTA={ctaType} CTADetail={ctaDetail} />
            )}
          </div>
          <img
            className={styles.carouselImg}
            src={ie || !isDesktopOrTablet ? imgStatic : img}
            alt={alt}
            style={styleProp}
          />
        </div>
      )}
    />
  )
}
