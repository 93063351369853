import React, { Component } from "react"
import Carousel from "react-bootstrap/Carousel"
import homeBannerImg1 from "../../../../assets/home-banner-img1.svg"
import homeBannerImg1Static from "../../../../assets/home-banner-img1-static.png"
import homeBannerImg2 from "../../../../assets/home-banner-img2.png"
import homeBannerImg3 from "../../../../assets/home-banner-img3.png"
import banner1bg from "../../../../assets/home-banner1.png"
import banner2bg from "../../../../assets/home-banner2.png"
import banner3bg from "../../../../assets/home-banner3.png"
import banner1bgmin from "../../../../assets/home-banner1-min.png"
import banner2bgmin from "../../../../assets/home-banner2-min.png"
import banner3bgmin from "../../../../assets/home-banner3-min.png"
import * as styles from "./carousel.module.scss"
import ModalVideo from "react-modal-video"
import "react-modal-video/scss/modal-video.scss"

import { Slide } from "./slide"
import { TM } from "../../common/typography/trademark"

export default class MainCarousel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ie: false,
      isDesktopOrTablet: true,
      howVideoIsOpen: false, // TODO: remove once carousel reverts
      isBrowser: false,
    }
    this.openModal = this.openModal.bind(this)
  }
  componentDidMount() {
    if (typeof window !== `undefined`) {
      let isIE = /*@cc_on!@*/ false || !!document.documentMode
      this.setState({
        ie: isIE,
        isBrowser: true,
      })
    }

    this.updateViewport()
    window.addEventListener("resize", this.updateViewport)

    //TODO: remove once carousel reverts
    if (!document.getElementById("wistia_script")) {
      var wistiaScript = document.createElement("script")
      wistiaScript.id = "wistia_script"
      wistiaScript.type = "text/javascript"
      wistiaScript.src = "https://fast.wistia.com/assets/external/E-v1.js"
      wistiaScript.async = true
      wistiaScript.popover = true
      document.body.appendChild(wistiaScript)
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateViewport)
    this.handle && this.handle.remove() // TODO: remove once carousel reverts
  }

  handleVideoLinkClick() {
    this.setState({ howVideoIsOpen: true })
  }

  openModal() {
    this.setState({ isOpen: true })
  }

  updateViewport = () => {
    this.setState({ isDesktopOrTablet: window.innerWidth > 812 })
  }

  render() {
    const slides = [
      {
        heading: "Post-Quantum",
        secondaryHeading: "Cyber Security",
        description: (
          <>
            We are an innovative leader in the new realm of Post-Quantum Cyber
            Security.{" "}
            <span
              onClick={() => this.handleVideoLinkClick()}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Learn more.
            </span>
          </>
        ),
        bg: banner1bg,
        bgMin: banner1bgmin,
        img: homeBannerImg1,
        imgStatic: homeBannerImg1Static,
        alt: "Post-Quantum Cyber Security Banner Image",
        styleProp: { width: "720px" },
        ctaType: "route",
        ctaDetail:
          "https://www.01com.com/pdf/2021/Rethinking-cybersecurity-in-a-quantum-world.pdf",
      },
      {
        heading: "Post-Quantum",
        secondaryHeading: (
          <>
            IronCAP X<TM />
          </>
        ),
        description: (
          <>
            IronCAP X<TM /> is the world's first end-to-end email/file
            encryption and digital signing system that utilizes the IronCAP
            <TM /> Toolkits as the underlying cryptographic system to ensure
            safety against cyber attacks from not only the conventional world of
            computers today but also against future attacks from quantum
            computers. IronCAP X<TM /> simply integrates with your everyday
            email client such as Outlook, Gmail, Yahoo, etc. to ensure zero
            learning curve.
          </>
        ),
        bg: banner2bg,
        bgMin: banner2bgmin,
        img: homeBannerImg2,
        imgStatic: homeBannerImg2,
        alt: "IronCAP X Banner Image",
        styleProp: { width: "45%", maxWidth: "500px", marginRight: "14.5%" },
        ctaType: "route",
        ctaDetail: "IronCAP X",
      },
      {
        heading: "Post-Quantum",
        secondaryHeading: (
          <>
            IronCAP
            <TM /> Toolkits
          </>
        ),
        description: (
          <>
            Our IronCAP
            <TM /> Toolkits allows vendors of a wide variety of vertical
            applications to easily transform their products to ensure safety
            against cyber attacks from not only the classical world of computers
            today but also against future attacks from quantum computers.
          </>
        ),
        bg: banner3bg,
        bgMin: banner3bgmin,
        img: homeBannerImg3,
        imgStatic: homeBannerImg3,
        alt: "IronCAP Toolkits Banner Image",
        styleProp: { width: "45%", maxWidth: "580px", marginRight: "13%" },
        ctaType: "route",
        ctaDetail: "IronCAP Toolkits",
      },
    ]

    return (
      <>
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
          integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
          crossorigin="anonymous"
        />
        <Carousel
          interval={6000}
          pauseOnHover={true}
          id={styles.carouselContainer}
        >
          {slides.map((slide, index) => (
            <Carousel.Item>
              <Slide
                bg={slide.bg}
                bgMin={slide.bgMin}
                img={slide.img}
                imgStatic={slide.imgStatic}
                ie={this.state.ie}
                isDesktopOrTablet={this.state.isDesktopOrTablet}
                slideNumber={index + 1}
                header={slide.heading}
                secondHeader={slide.secondaryHeading}
                description={slide.description}
                styleProp={slide.styleProp}
                ctaType={slide.ctaType}
                ctaDetail={slide.ctaDetail}
                // slide={propsobject}
              />
            </Carousel.Item>
          ))}
        </Carousel>
        {this.state.isBrowser && (
          <ModalVideo
            channel="youtube"
            isOpen={this.state.howVideoIsOpen}
            videoId="8K-mzNuEieQ"
            onClose={() => this.setState({ howVideoIsOpen: false })}
          />
        )}
      </>
    )
  }
}
